enum MutationType {
  Attributes = 0, // 'attributes',
  CharacterData = 1, // 'characterData',
  ChildList = 2, // 'childList',
}

// id, nodeName, attributes, children?
// id, nodeValue
export interface SerializedAttributes { [key: string]: string }
export type SerializedElement = readonly [number, string, SerializedAttributes, SerializedNode[]] | readonly [number, string, SerializedAttributes]
export type SerializedTextNode = readonly [number, string]
export type SerializedNode = SerializedElement | SerializedTextNode

export type SerializedChildListMutation = readonly [number, MutationType.ChildList, readonly SerializedNode[], readonly number[], any?]
export type SerializedCharacterDataMutation = readonly [number, MutationType.CharacterData, string, any?]
export type SerializedAttributesMutation = readonly [number, MutationType.Attributes, string, string, any?]
export type SerializedMutation = SerializedChildListMutation | SerializedCharacterDataMutation | SerializedAttributesMutation

export class DOMSerializer {
  private currentId: number
  private nodeToId: WeakMap<Node, number>
  constructor() {
    this.currentId = 0
    this.nodeToId = new WeakMap()
  }

  private indexNode(node: Node) {
    const id = this.currentId++
    this.nodeToId.set(node, id)
    return id
  }

  public getNodeId(node: Node) {
    // If node already indexed, return existing ID, else index the node
    return this.nodeToId.has(node) ? this.nodeToId.get(node)! : this.indexNode(node)
  }

  public serializeNode(node: Node, removeTextNodes = false): SerializedNode | null {
    // Filter out bad elements
    // if (node.nodeName === 'SCRIPT' || node.nodeName === 'STYLE')
    //   return null
    if (node.nodeType === Node.COMMENT_NODE || node.nodeName === 'SCRIPT')
      return null

    const id = this.getNodeId(node)

    // Special handling for text nodes
    if (node.nodeType === Node.TEXT_NODE) {
      if (!node.nodeValue || (removeTextNodes && /^\s+$/.test(node.nodeValue)))
        return null
      else return [id, node.nodeValue.replace(/\s+/, ' ')] as const // [id, nodeValue]
    }

    // Special handling for SVG elements
    if (node instanceof SVGElement)
      return [id, node.outerHTML] // [id, SVG as string]

    const serialized: readonly [number, string, { [key: string]: string }, any[]] = [id, node.nodeName, {}, []] // ["id", "nodeName", {attributes}?, [children]?]

    // Serialize attributes
    if (node instanceof Element && node.attributes && node.attributes.length) {
      // serialized[] = {} // Add attributes object
      for (const attr of node.attributes) {
        // Adjust this list of attributes as needed
        // if (['id', 'class', 'name', 'value', 'type', 'href', 'rel', 'charset', 'dir', 'integrity', 'src', 'onload', 'async', 'crossorigin', '', 'data-srcset'].includes(attr.name))
        serialized[2][attr.name] = attr.value
      }
    }

    for (const child of node.childNodes) {
      const serializedChild = this.serializeNode(child, removeTextNodes || node.nodeName === 'HEAD') // remove text nodes of all HEAD descendants
      if (serializedChild)
        serialized[3].push(serializedChild) // Add serialized child to children array
    }

    return serialized[3].length ? serialized : [serialized[0], serialized[1], serialized[2]]
  }

  public serializeMutation(mutation: MutationRecord): SerializedMutation {
    const targetId = this.getNodeId(mutation.target)
    const addedNodes = Array.from(mutation.addedNodes).map(node => this.serializeNode(node)).filter((node): node is SerializedNode => node !== null)
    const removedNodes = Array.from(mutation.removedNodes).map(this.getNodeId.bind(this))

    switch (mutation.type) {
      case 'attributes':
        // Calmate, this is going to have attributes
        return [targetId, MutationType.Attributes, mutation.attributeName as string, (mutation.target as Element).getAttribute(mutation.attributeName as string) as string, { nodeName: mutation.target.nodeName, type: mutation.target.nodeType }] as const
      case 'characterData':
        return [targetId, MutationType.CharacterData, mutation.target.nodeValue as string, { nodeName: mutation.target.nodeName, type: mutation.target.nodeType }] as const
      case 'childList':
        return [targetId, MutationType.ChildList, addedNodes, removedNodes, { nodeName: mutation.target.nodeName, type: mutation.target.nodeType }] as const
    }
  }
}

export class DOMDeserializer {
  private idToNode: Map<number, Node>
  constructor() {
    this.idToNode = new Map()
  }

  public getNodeById(id: number) {
    return this.idToNode.get(id)
  }

  private indexNode(id: number, node: Node) {
    this.idToNode.set(id, node)
  }

  private deserializeChildren(
    node: Node,
    children: SerializedNode[] | undefined,
  ) {
    if (children && children.length) {
      for (const serializedChild of children) {
        const child = this.deserialize(serializedChild)
        node.appendChild(child)
        // if (serializedChild)
        //   serialized[3].push(serializedChild) // Add serialized child to children array
      }
    }
  }

  public deserialize(serialized: SerializedNode): Node {
    // const [id, type, value] = serialized;
    const [id, ...rest] = serialized
    // if (id === 950) console.log("Deserializing", serialized);
    let node: Node | undefined = this.idToNode.get(id)
    if (rest.length === 1) {
      const [text] = rest
      // It's a text node or an svg node
      if (/<svg/.test(text)) {
        // It's SVG
        // node = document.createElementNS()
        const parser = new DOMParser()
        const svgDoc = parser.parseFromString(text, 'image/svg+xml')
        node = document.importNode(svgDoc.firstChild!, true)
      }
      else {
        node = document.createTextNode(text)
      }
    }
    else {
      const [nodeName, serializedAttributes, children] = rest
      node = document.createElement(nodeName)
      // debugger;
      for (const [k, v] of Object.entries(serializedAttributes))
        (node as HTMLElement).setAttribute(k, v)

      this.deserializeChildren(node, children)
    }
    this.indexNode(id, node)
    return node
  }

  public applyMutation(serializedMutation: SerializedMutation) {
    const [targetId, type, ...rest] = serializedMutation

    const targetNode = this.idToNode.get(targetId)

    if (!targetNode) {
      console.warn('Target node not found for mutation', serializedMutation)
      return
    }

    switch (type) {
      case MutationType.Attributes: {
        if (targetNode instanceof HTMLElement) {
          const [attribute, value] = rest
          targetNode.setAttribute(attribute as string, value as string)
        }
        else {
          console.warn(
            'Replayer: Target node not an HTMLElement for attribute mutation',
            serializedMutation,
          )
        }
        break
      }
      case MutationType.CharacterData: {
        const [nodeValue] = rest
        targetNode.nodeValue = nodeValue as string
        break
      }
      case MutationType.ChildList: {
        if (!(targetNode instanceof HTMLElement)) {
          console.warn(
            'Replayer: Target node not an HTMLElement for child list mutation',
            serializedMutation,
          )
          return
        }
        const [children, deletedIds] = rest
        this.deserializeChildren(targetNode, children as SerializedNode[])
        for (const id of deletedIds || []) {
          const nodeToRemove = this.idToNode.get(Number(id))
          if (id && nodeToRemove) {
            if (
              nodeToRemove instanceof HTMLElement
              && (nodeToRemove as HTMLElement).remove
            )
              nodeToRemove.remove()

            else
              targetNode.removeChild(nodeToRemove)
          }
        }
        break
      }
    }
  }
}
