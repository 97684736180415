import './polyfills'
import { buffer, filter, fromEvent, interval, merge, skip, startWith, switchMap, take, throttleTime } from 'rxjs'
import { Amplify } from 'aws-amplify'
import { record } from 'aws-amplify/analytics/kinesis'
import { createEverythingRecorderObservable, recordingSetup } from './recorder'
import type { ActionType, RecordingEventByActionType } from './types/recorder'
import { DOMSerializer } from './util/domSerializer'

// Roadmap:
// Run it when the page loads and log it when the session ends

interface InitConfig {
  LOG_ENDPOINT?: string
  disableWatch?: {
    [key in ActionType]?: boolean
  }
  token: string
  local?: boolean
}
// import.meta.env should be here but hey
const STREAM_NAME = import.meta.env.STREAM_NAME || 'kinesisStreamNlytics'
const STREAM_REGION = import.meta.env.STREAM_REGION || 'us-east-1'
const IDENTITY_POOL_ID = import.meta.env.IDENTITY_POOL_ID || 'us-east-1:fa8ee9ea-3864-48b5-b50c-e04f21142270'
const REST_API_ENDPOINT = import.meta.env.REST_API_ENDPOINT || 'https://sye7c2s5c3.execute-api.us-east-1.amazonaws.com/prod/'
const GEOLOCATE_API_ENDPOINT = import.meta.env.GEOLOCATE_API_ENDPOINT || 'https://ip.nlytics.co/prod/'

function shrink(events: RecordingEventByActionType[]) {
  return events.map(({ type, timestamp, data }) => [type, Math.round(timestamp), data] as const)
}

function parsePageId(pageId: string) {
  const [pageStartTimestamp, pageIndex] = pageId.split('#')
  return {
    pageStartTimestamp,
    pageIndex: parseInt(pageIndex),
  }
}

function mapOnKey(key: string, fn: (x: any) => any) {
  return (x: any) => {
    if (x[key])
      x[key] = fn(x[key])
    return x
  }
}

function freshPageIdParts(t: number = Date.now()) {
  return { pageStartTimestamp: (new Date(t)).toISOString(), pageIndex: 0 }
}

function makeSessionID(t: number, token: string) {
  const sessionTimestamp = (new Date(t)).toISOString()
  const sessionUUID = window.crypto.randomUUID().slice(0, 8).toUpperCase()
  const sessionId = `${sessionTimestamp}#${sessionUUID}`
  // Reset session tracker
  localStorage.setItem(`_n_id_${token}`, sessionId)
  return sessionId
}

function makePageId(t: number, sessionId: string) {
  const pageIdLocalStorageKey = `_n_page_id_${sessionId}`
  const savedPageId = localStorage.getItem(pageIdLocalStorageKey)
  const isNewPage = !savedPageId
  // const [pageTimestamp, pageIndex] =  || [null, null]
  const incrementPageIndex = mapOnKey('pageIndex', (x: number) => x + 1)
  const { pageStartTimestamp, pageIndex } = isNewPage ? freshPageIdParts(t) : incrementPageIndex(parsePageId(savedPageId))
  // const pageTimestamp = isNewPage ?  : savedPageId.split('#')[0]
  // const pageIndex = isNewPage ? 0 : parseInt(savedPageId.split('#')[1]) + 1
  const pageId = `${pageStartTimestamp}#${pageIndex}`
  // Persist it!
  localStorage.setItem(pageIdLocalStorageKey, pageId.toString())
  return pageId
}

function getIDs(config: Partial<InitConfig>) {
  if (!config.token)
    throw new Error('You must provide a token to init()')

  const savedSessionID = localStorage.getItem(`_n_id_${config.token}`)
  const savedLastVisit = localStorage.getItem(`_n_last_visit_${config.token}`)
  const lastVisit: Date | null = savedLastVisit ? new Date(savedLastVisit) : null

  // New session ID every 30 seconds
  const SESSION_TIMEOUT_MS = 30_000

  const isNewSession = !savedSessionID || !lastVisit || new Date().valueOf() - lastVisit.valueOf() > SESSION_TIMEOUT_MS

  const startTimestamp = Date.now()
  const sessionId = isNewSession ? makeSessionID(startTimestamp, config.token) : savedSessionID
  const pageId = makePageId(startTimestamp, sessionId)
  return { sessionId, pageId, startTimestamp }
}

export async function init(window: Window, config: Partial<InitConfig> = {}) {
  if (!config.token)
    throw new Error('You must provide a token to init()')

  // Configgure amplify
  Amplify.configure({
    Auth: {
      Cognito: {
        identityPoolId: IDENTITY_POOL_ID, // REQUIRED - Amazon Cognito Identity Pool ID
        // user: STREAM_REGION, // REQUIRED - Amazon Cognito Region
      },
    },
    // aws_user_files_s3_bucket_region: STREAM_REGION, // (required) - Amazon S3 bucket region
    // aws_user_files_s3_bucket: SESSION_UPLOAD_BUCKET, // (required) - Amazon S3 bucket URI
    // Storage: {
    //   AWSS3: {
    //     bucket: SESSION_UPLOAD_BUCKET,
    //     region: STREAM_REGION,
    //   },
    // },
    Analytics: {
      Kinesis: {
        region: STREAM_REGION, // https://docs.amplify.aws/lib/analytics/streaming/q/platform/js/#installation-and-configuration
        bufferSize: 20,
        flushSize: 20,
        flushInterval: 10000,
        resendLimit: 5,
      },
    },
    // Analytics: {
    //   region: STREAM_REGION, // https://docs.amplify.aws/lib/analytics/streaming/q/platform/js/#installation-and-configuration
    //   bufferSize: 10,
    //   flushSize: 10,
    //   flushInterval: 3000,
    //   resendLimit: 5,
    //   AWSKinesis: {
    //     region: STREAM_REGION, // https://docs.amplify.aws/lib/analytics/streaming/q/platform/js/#installation-and-configuration
    //     bufferSize: 10,
    //     flushSize: 10,
    //     flushInterval: 3000,
    //     resendLimit: 5,
    //   },
    // },
  })

  const { sessionId, pageId, startTimestamp } = getIDs(config)
  // function getDateFromSessionID(sessionID: string) {
  //   return new Date(sessionID.split('$')[0])
  // }

  // TODO: start recording before we get all this
  const tokenPromise = fetch(new URL('token', REST_API_ENDPOINT), {
    method: 'POST',
    body: JSON.stringify({
      token: config.token,
    }),
  }).then(res => res.json()).then(res => res.jwt)
  let globalJWTToken: string | null = null
  tokenPromise.then((token) => {
    localStorage.setItem('_n_token', token)
    globalJWTToken = token
  })
  // console.log(tokenPromise)

  // localStorage.setItem('_n_id', sessionId)

  function recordLastVisit() {
    localStorage.setItem(`_n_last_visit_${config.token}`, (new Date()).toISOString())
  }
  recordLastVisit()

  // Create the serializer, which carries some mutable state also unfortunately
  const domSerializer = new DOMSerializer()

  // Load it
  const MAX_CHUNK_SIZE = 1e6

  let lastOrderKey = 0
  function getLastOrderKey() {
    return lastOrderKey++
  }

  function str2ab(str: string) {
    const buf = new Uint8Array(str.length) // 2 bytes for each char
    // const bufView = new Uint16Array(buf)
    for (let i = 0, strLen = str.length; i < strLen; i++)
      buf[i] = str.charCodeAt(i)

    return buf
  }

  function encodeToUint8Array(str: string) {
    if (typeof TextEncoder === 'function')
      return new TextEncoder().encode(str)

    else
      return str2ab(str)
  }

  async function recordWithToken(orderKey: number, data: any) {
    const token = await tokenPromise
    return record({
      data: encodeToUint8Array(`${token},${sessionId},${pageId},${orderKey}::${data}`),
      partitionKey: `${config.token}#${sessionId}`, // interesting thing here bc we don't include session uuid (we're 2 chars over, we MUST rely on firehose...)
      streamName: STREAM_NAME,
    })
  }

  function tokenIsExpiredNoValidate() {
    const token = globalJWTToken
    if (!token)
      return false

    const { exp } = JSON.parse(atob(token.split('.')[1]))
    return exp < Date.now() / 1000
  }

  function pushPayloadToKinesis(payload: any) {
    const jsonified = JSON.stringify(payload)
    console.debug('Pushing payload to kinesis', payload, jsonified.length) // eslint-disable-line no-console

    // Break it up into chunks if it is too big
    if (jsonified.length > MAX_CHUNK_SIZE) {
      const nChunks = Math.ceil(jsonified.length / MAX_CHUNK_SIZE)
      console.debug('Payload will be chunked, too big', `${jsonified.length} > ${MAX_CHUNK_SIZE}`, `nChunks=${nChunks}`) // eslint-disable-line no-console
      for (let i = 0; i < jsonified.length; i += MAX_CHUNK_SIZE) {
        const orderKey = getLastOrderKey()
        const chunk = jsonified.slice(i, i + MAX_CHUNK_SIZE)
        recordWithToken(orderKey, chunk).then(results => console.debug('Got results from chunked push', results)) // eslint-disable-line no-console
      }
    }
    // Otherwise, just push it
    else {
      const orderKey = getLastOrderKey()
      recordWithToken(orderKey, jsonified)
    }
  }

  function pushRecordingEvents(events: RecordingEventByActionType[]) { // dummy function for now
    if (events.length === 0)
      return

    if (tokenIsExpiredNoValidate())
      return console.error('Token is expired, not pushing to kinesis')
    pushPayloadToKinesis(shrink(events))
  }

  // Ping this before the page loads
  const ping = fetch(GEOLOCATE_API_ENDPOINT).then(x => x.json()).catch(() => fetch('https://ipapi.co/json/').then(x => x.json()).catch(() => fetch('https://www.cloudflare.com/cdn-cgi/trace').then(x => x.text()).then(x => Object.fromEntries(x.split('\n').map(x => x.split('='))))).catch(() => null))

  function handleLoad() {
    // Start the recording and send state setup (ooh alliteration) to the thingum
    // const token = tokenPromise
    // const startTimestamp = Date.now()
    tokenPromise.then(token => recordingSetup(window, window.document, domSerializer, startTimestamp, ping).then((setup) => {
      // Storage.put(`${token}/${sessionId}.${pageId}.json`, JSON.stringify(setup))
      const sessionApiUrl = new URL('setup', REST_API_ENDPOINT)
      sessionApiUrl.search = (new URLSearchParams({
        token,
        sessionId,
        pageId: pageId.toString(),
      })).toString()
      fetch(sessionApiUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(setup),
        method: 'POST',
      }) // could also be fetch'd but why?
      if (config.local) {
        // set global state
        // @ts-expect-error - this is fine
        window._nlytics_setup = setup
      }
    }))

    const recorder$ = createEverythingRecorderObservable(window, window.document, domSerializer, startTimestamp, config)

    // console.log('Putting setup', setup)

    // Take all the session ending events
    const beforeUnload$ = fromEvent(window, 'beforeunload')
    const visibilityChange$ = fromEvent(window.document, 'visibilitychange').pipe(filter(() => document.visibilityState === 'hidden'))
    const blur$ = fromEvent(window, 'blur')
    const sessionEnding$ = merge(beforeUnload$, visibilityChange$, blur$)
    const start$ = recorder$.pipe(skip(3), take(1))

    // Create a buffer that executes every 10_000 ms and every time a session-ending event occurs
    // x--10--10-x--10-x-x--10--10--10--10
    // Where every x is a danger event and every 10 is an interval elapsing
    const bufferer$ = buffer<RecordingEventByActionType>(merge(start$, sessionEnding$).pipe(switchMap(() => interval(5_000).pipe(startWith(0))))) // it's a shame that this can only handle a recording event
    recorder$.pipe(bufferer$).subscribe(pushRecordingEvents)
    recorder$.pipe(throttleTime(10_000)).subscribe(recordLastVisit)
    if (config.local) {
      // set global state
      recorder$.subscribe((x) => {
        // @ts-expect-error - this is fine
        window._nlytics_events = window._nlytics_events || []
        // @ts-expect-error - this is fine
        window._nlytics_events.push(x)
      })
    }
  }

  if (document.readyState === 'complete')
    handleLoad()
  else
    window.addEventListener('load', handleLoad)

  console.log('💅 Nlytics Tag Initialized') // eslint-disable-line no-console
}
